<template>
  <div style="margin-top: 100px">
    <v-row>
      <v-col md="6" cols="12" sm="12"
        ><img :src="course.newImageUrl" style="width: 90%; border-radius: 30px"
      /></v-col>
      <v-col md="6" cols="12" sm="12"
        ><h2>{{ course.name }}</h2>
        <p v-html="course.description" style="margin-top: 30px"></p>
        <v-btn
          @click="$root.$emit('openLogin')"
          elevation="2"
          depressed
          dark
          color="turquoise"
          v-if="!$store.state.accessToken"
        >
          Iniciar sesión</v-btn
        >
        <div v-if="course.isPaidCourse">
          <div v-if="!course.userPaid">
            <p style="font-size: 20px">{{ course.price | toCurrency}}<br /></p>
            <v-row v-if="$store.state.accessToken">
              <v-col cols="12" md="4">
                <div v-if="!loadingPaymentMethods">
                  <v-select
                    v-model="cardSelected"
                    :items="paymentMethods"
                    attach
                    outlined
                    item-text="maskedNumber"
                    return-object
                    label="Selecciona la tarjeta o crea una nueva"
                  ></v-select>
                </div>
                <v-btn
                  elevation="2"
                  depressed
                  dark
                  style="width: 100%;"
                  color="turquoise"
                  @click="PayStripe(cardSelected.id)"
                  >Pagar</v-btn
                >
                <p v-if="errorPago">
                  Ocurrió un problema con tu método de pago, por favor intenta
                  con otro
                </p>
                <v-btn
                  elevation="2"
                  class="mt-2"
                  style="width: 100%;"
                  depressed
                  dark
                  color="turquoise"
                  @click="showNewMethod = true"
                  >Agregar un método de pago</v-btn
                >
                <v-btn
                  style="width: 100%;"
                  @click="addToCart()"
                  class="mt-2"
                  elevation="2"
                  depressed
                  dark
                  color="turquoise"
                >
                  Agregar al carrito</v-btn
                >
                <v-btn
                  class="mt-2"
                  elevation="2"
                  depressed
                  dark
                  style="width:100%"
                  color="turquoise"
                  @click="payOxxoStripe()"
                >
                  Pagar en oxxo
                </v-btn>
                <PayPal
                  style="width: 100%; "
                  :amount="String(course.price)"
                  currency="MXN"
                  class="mt-2"
                  v-on:payment-authorized="paymentAuthorized"
                  v-on:payment-completed="paymentCompleted"
                  v-on:payment-cancelled="paymentCancelled"
                  :client="paypal"
                  :button-style="myStyle"
                ></PayPal>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-row v-if="!course.isPaidCourse || course.userPaid">
          <v-col
            v-if="course.downloads && course.downloads.length > 0"
            cols="12"
          >
            <h2 style="margin-top: 20px; margin-bottom: 20px">
              Contenidos descargables
            </h2>
            <div v-for="download in course.downloads" v-bind:key="download.id">
              <v-row style="margin-bottom:20px;">
                <v-btn :href="download.url" target="_blank" color="primary">{{
                  download.name
                }}</v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" v-if="course.briefVideoUrl">
        <h2 style="text-align: center">Video promocional</h2>
        <vimeo-player
          style="border-radiux: 30px;"
          ref="player"
          :video-url="course.briefVideoUrl"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="course.testimonialVideoUrl">
        <h2 style=" text-align: center">Testimonial</h2>
        <vimeo-player
          style="border-radiux: 30px;"
          ref="player"
          :video-url="course.testimonialVideoUrl"
        />
      </v-col>
      <v-col cols="12" md="2" style="margin: auto;" v-if="course.id == 52">
        <h2 style=" text-align: center">Energización del Agua</h2>
        <img src="https://storage.googleapis.com/zertuimages/cursos-mayo-2021/detox_water.jpg" style="width: 100%" />
      </v-col>
    </v-row>
    <v-row
      v-if="
        (!course.isPaidCourse || course.userPaid) && $store.state.accessToken
      "
    >
      <v-col v-if="course.audioBooks.length > 0" cols="4">
        <div v-for="audiobook in course.audioBooks" v-bind:key="audiobook.id">
          <v-card>
            <v-card-title primary-title>
              <div>
                {{ audiobook.name }}
              </div>
            </v-card-title>
            <audio controls>
              <source :src="audiobook.url" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </v-card>
        </div>
      </v-col>
      <v-col v-if="course.videos.length > 0" cols="12">
        <h2 style="margin-top: 20px; margin-bottom: 20px">
          Videos disponibles
        </h2>
        <div v-for="video in course.videos" v-bind:key="video.id">
          <v-row>
            <v-col cols="12" md="4">
              <vimeo-player
                style="border-radiux: 30px; width: 100%"
                ref="player"
                :video-url="video.url"
              />
            </v-col>
            <v-col cols="12" md="8">
              <p style="font-size: 30px">{{ video.name }}</p>
              <p v-html="video.description"></p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showPaying" max-width="400">
      <v-card :loading="paying">
        <v-card-text v-if="!paymentError && paying">
          <p style="font-size: 20px; text-align: center; padding-top: 20px">
            Estamos procesando tu pago por favor espera
          </p>
        </v-card-text>
        <v-card-text v-if="paymentError && !paying">
          <p style="font-size: 20px; text-align: center; padding-top: 20px">
            Ocurrió un problema con tu pago, por favor intenta con otra tarjeta.
          </p>
          <v-btn
            dark
            class="purple"
            @click="showPaying = false"
            style="width: 100%"
            >Intentar con otro método</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showError" max-width="400">
      <v-card>
        <v-card-text>
          <p
            class="error--text"
            style="font-size: 20px; text-align: center; padding-top: 20px"
          >
            {{
              errorMessage
                ? errorMessage
                : "El producto ya se encuentra agregado al carrito"
            }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog overlay fullscreen v-model="showNewMethod">
      <CreditCard :cerrarFormaPago="cerrarFormaPago"></CreditCard>
    </v-dialog>
    <v-snackbar v-model="snackbar" absolute bottom color="success" outlined>
      Curso agregado al carrito
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { structOrder } from "../helpers/paymentOrderHelpers";
import CreditCard from "../components/CreditCard.vue";
import PayPal from "vue-paypal-checkout";
export default {
  data() {
    return {
      snackbar: false,
      course: null,
      loading: true,
      showError: false,
      paying: true,
      paymentError: false,
      showPaying: false,
      errorMessage: null,
      loadingPaymentMethods: false,
      paymentMethods: null,
      errorPago: false,
      showNewMethod: false,
      myStyle: {
        label: "checkout",
        size: "responsive",
        shape: "rect",
        color: "gold",
      },
    };
  },
  components: {
    PayPal,
    CreditCard,
  },
  mounted() {
    this.loadCourse();
    this.getPaymentMethodList();
    this.$root.$on("closeCard", () => {
      this.showNewMethod = false;
      this.getPaymentMethodList();
    });
  },
  methods: {
    /*getPaymentMethodList() {
      if(!this.$store.state.accessToken) return
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .get("/user/payment/list", { headers: headers })
        .then((response) => {
          this.loadingPaymentMethods = false;
          this.paymentMethods = response.data.response;
          this.paymentMethods.forEach((element) => {
            if (element.defaultMethod) {
              this.cardSelected = element;
            }
          });
        }).catch(e => {
          if(e.response.status === 401)
            this.$store.dispatch('LogoutException')
        })
    },*/
    getPaymentMethodList() {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .get("/user/stripe/payment/list", { headers: headers })
        .then((response) => {
          this.loadingPaymentMethods = false;
          this.paymentMethods = response.data.response;
          this.paymentMethods.forEach((element) => {
            if (element.defaultMethod) {
              this.cardSelected = element;
            }
          });
        });
    },
    PayStripe(token) {
      const data = [this.course];
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      const request = {
        paymentMethodId: token,
        courseList: data,
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/cart/stripe/pay", request, { headers: headers })
        .then((response) => {
          this.paying = false;
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.$router.push({
              name: "gracias",
              params: { receipt: response.data.response },
            });
          }
        });
    },
    pay() {
      const data = [this.course];
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/cart/conekta/pay", data, { headers: headers })
        .then((response) => {
          this.paying = false;
          console.log(response);
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.$router.push({
              name: "gracias",
              params: { receipt: response.data.response },
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 401)
            this.$store.dispatch("LogoutException");
        });
    },
    paymentAuthorized: function(data) {
      console.log(data);
    },
    paymentCompleted: function(data) {
      console.log(data);
      this.payPaypal(data.id);
    },
    paymentCancelled: function(data) {
      console.log(data);
      this.paymentError = true;
    },
    payPaypal(orderId) {
      const courses = [];
      courses.push(this.course);
      this.showPaying = true;
      this.paymentError = false;
      this.paying = true;
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/cart/paypal/pay/" + orderId, courses, { headers: headers })
        .then((response) => {
          this.paying = false;
          console.log(response);
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.$router.push({
              name: "gracias",
              params: { receipt: response.data.response },
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 401)
            this.$store.dispatch("LogoutException");
        });
    },
    payOxxo() {
      const course = [];
      course.push(this.course);
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/cart/conekta/pay-oxxo", course, { headers: headers })
        .then((response) => {
          this.paying = false;
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            this.$store.commit("emptyCart");
            this.axios
              .get(
                `/course/payments-by-order/${response.data.response.ticket.receipt}`
              )
              .then((res) => {
                this.$store.commit("setPaymentReference", null);
                const order = structOrder(res.data);
                this.$store.commit("setPaymentReference", order[0]);
                this.$router.push("/payment-reference");
              })
              .catch((e) => {
                if (e.response.status === 401)
                  this.$store.dispatch("LogoutException");
              });
          }
        })
        .catch((e) => {
          this.paying = false;
          this.errorMessage = e.response.data.message;
          this.showError = true;
          this.showPaying = false;
          setTimeout(() => {
            this.showError = false;
            this.errorMessage = null;
          }, 2000);
        });
    },
    payOxxoStripe() {
      const course = [];
      course.push(this.course);
      let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios
        .post("/cart/stripe/pay-oxxo", course, { headers: headers })
        .then((response) => {
          this.paying = false;
          if (response.data.code == 500) {
            this.paymentError = true;
          } else {
            console.log(response.data.response.ticket.receipt)
            this.$store.commit("emptyCart");
            this.$store.commit("setPaymentReference", null);
            window.open(response.data.response.ticket.receipt, '_blank')
            
          }
        })
        .catch((e) => {
          this.paying = false;
          this.errorMessage = e.response.data.message;
          this.showError = true;
          this.showPaying = false;
          setTimeout(() => {
            this.showError = false;
            this.errorMessage = null;
          }, 2000);
        });
    },
    addToCart() {
      const cart = this.$store.state.cart;
      if (cart.find((x) => x.id === this.course.id)) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 2000);
      } else {
        this.$store.commit("addCourseToCart", this.course);
        this.snackbar = true;
      }
    },
    loadCourse() {
      var headers = {};
      if (this.$store.state.accessToken) {
        headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.accessToken,
        };
      } else {
        headers = {
          "Content-Type": "application/json",
        };
      }
      this.axios
        .get("/course/" + this.$route.params.id, { headers: headers })
        .then((response) => {
          this.loading = false;
          this.course = response.data.response;
          console.log(response.data.response);
        })
        .catch((e) => {
          if (e.response.status === 401)
            this.$store.dispatch("LogoutException");
        });
    },
    cerrarFormaPago() {
      this.showNewMethod = false;
    },
  },
  computed: {
    ...mapState(["paypal"]),
  },
};
</script>
